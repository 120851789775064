import React from 'react';

import LoadingSpinner from '../components/LoadingSpinner';
import OrdersList from '../components/OrdersList';
import { usePendingOrders } from '../services/OrdersService';


export default function PendingOrders() {
  const pendingOrders = usePendingOrders();

  return (
    <>
      <OrdersList
        title="Pendents d'entrega"
        navBarTab="pending"
        orders={pendingOrders ?? []}
      />

      {
        pendingOrders === undefined
          ? (
            <LoadingSpinner />
          )
          : null
      }
    </>
  );
}
