import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Observer, Subject, Subscription } from 'rxjs';

import config from '../config';
import authService from './AuthService';


export type TimeSlot = {
  startTime: Date,
  endTime: Date,
  remainingOrders: number;
};

export type TimeSlotDefinition = {
  startTime: Date,
  endTime: Date,
  maxOrders: number;
};

export type Event = null | Array<TimeSlot>;


class SlotsService {
  private eventDispatcher: Subject<Event>;

  private _timeSlots: Array<TimeSlot> | undefined;

  public constructor() {
    this.eventDispatcher = new Subject();
    this._timeSlots = undefined;

    authService.subscribe({
      next: currentUser => {
        if (currentUser !== null) {
          this.refreshTimeSlots();
        } else {
          this._timeSlots = undefined;
        }
      }
    });
  }

  public subscribe(observer: Partial<Observer<Event>>): Subscription {
    return this.eventDispatcher.subscribe(observer);
  }

  public async refreshTimeSlots() {
    const res = await fetch(new URL('slots', config.baseUrl).toString(), {
      credentials: 'same-origin',
    });
    if (res.status === 200) {
      this._timeSlots = (await res.json())['slots']
        .map((it: any) => ({
          startTime: dayjs(it.startTime).toDate(),
          endTime: dayjs(it.endTime).toDate(),
          remainingOrders: it.remainingOrders,
        }));
      this.eventDispatcher.next(this._timeSlots ?? null);
    }
  }

  public get timeSlots() {
    return this._timeSlots;
  }

  public async createPreset(name: string): Promise<void> {
    const res = await fetch(new URL(`slots/preset/${encodeURIComponent(name)}`, config.baseUrl).toString(), {
      method: 'POST',
      credentials: 'same-origin',
    });
    if (res.status !== 200) {
      throw new Error((await res.json())['error']);
    }
  }

  public async deletePreset(name: string): Promise<void> {
    const res = await fetch(new URL(`slots/preset/${encodeURIComponent(name)}`, config.baseUrl).toString(), {
      method: 'DELETE',
      credentials: 'same-origin',
    });
    if (res.status !== 200) {
      throw new Error((await res.json())['error']);
    }
  }

  public async fetchPresetInfo(name: string): Promise<Array<TimeSlotDefinition>> {
    const res = await fetch(new URL(`slots/preset/${encodeURIComponent(name)}`, config.baseUrl).toString(), {
      credentials: 'same-origin',
    });
    if (res.status !== 200) {
      throw new Error((await res.json())['error']);
    }

    const resBody = await res.json();
    return resBody['preset']
      .map((it: any) => ({
        startTime: dayjs('1970-01-01 ' + it.startTime).toDate(),
        endTime: dayjs('1970-01-01 ' + it.endTime).toDate(),
        maxOrders: it.maxOrders,
      } as TimeSlotDefinition));
  }

  public async applyPreset(name: string, targetDate: Date): Promise<void> {
    const res = await fetch(new URL(`slots/preset/${encodeURIComponent(name)}/apply`, config.baseUrl).toString(), {
      method: 'POST',
      body: new URLSearchParams({
        targetDate: dayjs(targetDate).tz('UTC', true).format('YYYY-MM-DD'),
      }),
      credentials: 'same-origin',
    });
    if (res.status !== 200) {
      throw new Error((await res.json())['error']);
    }
  }

  public async modifyPreset(
    name: string,
    delta:
      | Array<TimeSlotDefinition>
      | { add: Array<TimeSlotDefinition> }
      | { del: Array<Date> },
  ): Promise<Array<TimeSlotDefinition>> {
    let body: any;
    if (Array.isArray(delta)) {
      body = delta.map(it => ({
        startTime: dayjs(it.startTime).tz('UTC', true).format('HH:mm:ss'),
        endTime: dayjs(it.endTime).tz('UTC', true).format('HH:mm:ss'),
        maxOrders: it.maxOrders,
      }));
    } else {
      body = {};
      if ('add' in delta) {
        body = {
          add: delta['add'].map(it => ({
            startTime: dayjs(it.startTime).tz('UTC', true).format('HH:mm:ss'),
            endTime: dayjs(it.endTime).tz('UTC', true).format('HH:mm:ss'),
            maxOrders: it.maxOrders,
          })),
        };
      }
      if ('del' in delta) {
        body = {
          del: delta['del'].map(it => dayjs(it).format('HH:mm:ss')),
          ...body,
        };
      }
    }

    const res = await fetch(new URL(`slots/preset/${encodeURIComponent(name)}`, config.baseUrl).toString(), {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(body),
      credentials: 'same-origin',
    });
    if (res.status !== 200) {
      throw new Error((await res.json())['error']);
    }

    const resBody = await res.json();
    return resBody['preset']
      .map((it: any) => ({
        startTime: dayjs('1970-01-01 ' + it.startTime).toDate(),
        endTime: dayjs('1970-01-01 ' + it.endTime).toDate(),
        maxOrders: it.maxOrders,
      } as TimeSlotDefinition));
  }

  public async fetchAllTimeSlots(): Promise<Array<TimeSlot>> {
    const res = await fetch(new URL('slots/all', config.baseUrl).toString(), {
      credentials: 'same-origin',
    });
    if (res.status !== 200) {
      throw new Error((await res.json())['error']);
    }

    const resBody = await res.json();
    return resBody['slots']
      .map((it: any) => ({
        startTime: dayjs(it.startTime).toDate(),
        endTime: dayjs(it.endTime).toDate(),
        remainingOrders: it.remainingOrders,
      } as TimeSlot));
  }

  public async fetchTimeSlotPresets(): Promise<Array<string>> {
    const res = await fetch(new URL('slots/preset', config.baseUrl).toString(), {
      credentials: 'same-origin',
    });
    if (res.status !== 200) {
      throw new Error((await res.json())['error']);
    }

    const resBody = await res.json();
    return resBody['presets'];
  }
}

const slotsService = new SlotsService();
export default slotsService;


export function useTimeSlots() {
  const [timeSlots, setTimeSlots] = useState<Event>([]);

  useEffect(() => {
    const subscription = slotsService.subscribe({
      next: event => setTimeSlots(event),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return timeSlots;
}
