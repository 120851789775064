import React, { useRef, useState } from 'react';

import './Alert.css';


export default function Alert(props: {
  title: string;
  message?: string;
  type: 'confirm' | 'message' | 'retry';

  onDismiss: (btnRole: 'ok' | 'cancel') => void;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  function onClickBackdrop(event: React.MouseEvent<HTMLDivElement>) {
    if (event.target === containerRef.current) {
      props.onDismiss('cancel');
    }
  }

  let buttons;
  switch (props.type) {
    case 'confirm':
      buttons = (
        <>
          <button
            className="alert-button"
            onClick={() => props.onDismiss('cancel')}
          >Cancel·la</button>
          <button
            className="alert-button"
            onClick={() => props.onDismiss('ok')}
          >D'acord</button>
        </>
      );
      break;
    case 'message':
      buttons = (
        <button
          className="alert-button"
          onClick={() => props.onDismiss('ok')}
        >D'acord</button>
      );
      break;
    case 'retry':
      buttons = (
        <button
          className="alert-button"
          onClick={() => props.onDismiss('ok')}
        >Tornar a provar</button>
      );
      break;
  }

  return (
    <div ref={containerRef} className="alert-container" onClick={onClickBackdrop}>
      <div className="alert-dialog">
        <div className="alert-title">{props.title}</div>
        <div className="alert-content">{props.message}</div>
        <div className="alert-buttons">{buttons}</div>
      </div>
    </div>
  );
}


export function useAlert() {
  const [alertProps, setAlertProps] = useState<null | Parameters<typeof Alert>[0]>(null);

  return {
    show: (props: Parameters<typeof Alert>[0]) => setAlertProps(props),
    hide: () => setAlertProps(null),

    render: () => alertProps !== null
      ? (
        <Alert {...alertProps} />
      )
      : null,
  };
}
