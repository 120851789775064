import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useAlert } from '../components/Alert';
import { useDialogMenu } from '../components/DialogMenu';
import Header from '../components/Header';
import LoadingSpinner from '../components/LoadingSpinner';
import NavBar from '../components/NavBar';
import Tour from '../components/Tour';
import authService, { useCurrentUser } from '../services/AuthService';
import { useAllOrders } from '../services/OrdersService';

import './Home.css';


export default function Home() {
  const alert = useAlert();
  const dialogMenu = useDialogMenu();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const allOrders = useAllOrders();

  const [mustWait, setMustWait] = useState(false);
  const [hasPrevOrder, setHasPrevOrder] = useState(false);

  const menuButtons = [
    {
      text: 'Edita el perfil',
      role: 'profile',
    },
    {
      text: 'Tancar la sessió',
      role: 'sign-out',
    },
  ];

  let btnIdx = 1;
  switch (currentUser!.userType) {
    case 'admin':
      menuButtons.splice(btnIdx, 0, {
        text: 'Gestiona franges',
        role: 'admin-slots',
      });
      btnIdx++;
    // eslint-disable-next-line no-fallthrough
    case 'delivery':
      menuButtons.splice(btnIdx, 0, {
        text: 'Gestiona entregues',
        role: 'admin-deliveries',
      });
      btnIdx++;
  }

  async function onClick() {
    if (mustWait || allOrders === undefined) {
      return;
    }

    const today = new Date();
    let date = allOrders
      .filter(it => it.status === 'open')
      .find(it => true
        && it.date.getFullYear() === today.getFullYear()
        && it.date.getMonth() === today.getMonth()
        && it.date.getDate() === today.getDate()
      )
      ?.date;
    if (date === undefined) {
      navigate(`/new-order`);
      return;
    }

    navigate(`/edit/${dayjs(date).format('YYYY-MM-DD')}`);
  }

  useEffect(() => {
    if (allOrders === undefined) {
      setHasPrevOrder(false);
      return;
    }

    const today = new Date();
    setMustWait(allOrders.find(it => true
      && it.status !== 'open'
      && it.date.getFullYear() === today.getFullYear()
      && it.date.getMonth() === today.getMonth()
      && it.date.getDate() === today.getDate()
    ) !== undefined);
    setHasPrevOrder(allOrders.find(it => it.status === 'open') !== undefined);
  }, [allOrders]);

  return (
    <div className="home-container">
      <Header
        withBackButton
        backButtonTo={() => dialogMenu.show({
          title: 'Opcions',
          buttons: menuButtons,
          onDismiss: role => {
            dialogMenu.hide();
            switch (role) {
              case 'profile':
                navigate('/profile');
                break;
              case 'sign-out':
                authService.signOut();
                break;
              case 'admin-slots':
                navigate('/admin/slots');
                break;
              case 'admin-deliveries':
                navigate('/delivery');
                break;
              default:
                break;
            }
          },
        })}
        backButtonType="open-menu"
      />

      <div className="home-tour">
        <Tour />
      </div>

      <button className="home-button" onClick={onClick}>
        {
          mustWait
            ? <>Fins demà!</>
            : (
              hasPrevOrder
                ? <>Continua la compra</>
                : <>Inicia la compra</>
            )
        }
      </button>

      <NavBar />

      {alert.render()}
      {dialogMenu.render()}
      {
        allOrders === undefined
          ? (
            <LoadingSpinner />
          )
          : null
      }
    </div>
  );
}
