import config from '../config';


export type ShopInfo = {
  name: string;
  textId: string;
}


class ShopsService {
  public async fetchPublicShops(): Promise<Array<ShopInfo>> {
    const res = await fetch(new URL(`shops`, config.baseUrl).toString(), {
      credentials: 'same-origin',
    });
    if (res.status !== 200) {
      throw new Error((await res.json())['error']);
    }

    return (await res.json())['shops'];
  }
}

const shopsService = new ShopsService();
export default shopsService;
