import React from 'react';


export default function Slide1() {
  return (
    <>
      <div className="tour-description">
        Inicia la compra a la teva botiga de confiança
      </div>

      <div className="tour-step">
        Comença a comprar
        <div className="tour-step-note">
          &nbsp;&nbsp;&nbsp;&nbsp;
          Nosaltres t'ho portem a casa!
        </div>
      </div>
    </>
  );
}
