import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../../components/Layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import ordersService from '../../services/OrdersService';


export default function ReceiverDetails() {
  const params = useParams<'nif' | 'orderId'>();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [nif, setNif] = useState('');
  const [isLoading, setLoading] = useState(false);

  async function onSubmit() {
    setLoading(true);
    let isFailure = false;
    try {
      await ordersService.deliverOrder(params.nif!, params.orderId!, { name, nif });
    } catch (e) {
      isFailure = true;
      // TODO: Report error.
    } finally {
      setLoading(false);
    }

    if (!isFailure) {
      navigate('/delivery');
    }
  }

  return (
    <Layout
      title="Entregar comanda"
      withBackButton
      backButtonTo="/delivery"
    >
      <div className="presetcreate-item">
        <span className="presetcreate-item-label">Nom de qui l'ha rebuda:</span>
        <input
          type="text"
          value={name}
          onChange={event => setName(event.target.value)}
        />
      </div>

      <div className="presetcreate-item">
        <span className="presetcreate-item-label">NIF de qui l'ha rebuda:</span>
        <input
          type="text"
          value={nif}
          onChange={event => setNif(event.target.value)}
        />
      </div>

      <button className="presetcreate-button" onClick={onSubmit}>Confirma la entrega</button>

      {
        isLoading
          ? <LoadingSpinner />
          : null
      }
    </Layout>
  );
}
