import React from 'react';


export default function Slide5() {
  return (
    <>
      <div className="tour-description">
        <i className="bi-truck" />
      </div>

      <div className="tour-step">
        Nosaltres ho fem per tu
      </div>
    </>
  );
}
