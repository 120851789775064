import React, { PropsWithChildren } from 'react';

import Header from './Header';
import Title from './Title';

import './Layout.css';
import NavBar from './NavBar';


export default function Layout(props: PropsWithChildren<{
  title: string;

  withNavBar?: boolean;
  navBarTab?: 'pending' | 'delivered';

  withBackButton?: boolean;
  backButtonTo?: string;
}>) {
  return (
    <div className={props.withNavBar ? "layout-fullheight" : undefined}>
      <Header
        withBackButton={props.withBackButton}
        backButtonTo={props.backButtonTo}
      />

      <div className="layout-content">
        <Title text={props.title} />
        <div className="layout-spacer" />

        {props.children}
      </div>

      {
        props.withNavBar === true
          ? (
            <NavBar currentTab={props.navBarTab} />
          )
          : null
      }
    </div>
  );
}
