import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAlert } from '../components/Alert';
import SelectTimeSlot from '../components/SelectTimeSlot';
import ordersService from '../services/OrdersService';
import QrScanner from './QrScanner';


export default function OrderCreate() {
  const alert = useAlert();
  const navigate = useNavigate();
  const searchParams = useSearchParams({ createOrder: 'true' })[0];

  const isOrderCreated = useRef<boolean>(searchParams.get('createOrder') === 'true' ? false : true);

  const [page, setPage] = useState<'time-slot' | 'qr-code'>('time-slot');
  const [timeSlot, setTimeSlot] = useState<null | Date>(null);

  async function onSelectTimeSlot(timeSlotStart: Date) {
    setPage('qr-code');
    setTimeSlot(timeSlotStart);
  }

  function onGoBack() {
    if (page === 'qr-code') {
      setPage('time-slot');
    } else {
      navigate(-1);
    }
  }

  async function onQrCodeReady(shopId: string) {
    if (!isOrderCreated.current) {
      try {
        await ordersService.createOrder();
      } catch (e) {
        alert.show({
          type: 'message',
          title: 'Error',
          message: 'Hi ha hagut un error al intentar crear la comanda. '
            + 'Torna-ho a intentar més tard.',
          onDismiss: () => {
            alert.hide();
            navigate('/');
          },
        });

        return;
      }
      isOrderCreated.current = true;
    }

    try {
      const date = new Date();
      await ordersService.modifyOrder(`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`, {
        timeSlot: timeSlot!,
        shops: {
          add: [shopId],
        },
      });
    } catch (e) {
      alert.show({
        type: 'message',
        title: 'Error',
        message: 'Hi ha hagut un error al intentar crear la comanda. '
          + 'Torna-ho a intentar més tard.',
        onDismiss: () => {
          alert.hide();
          navigate('/');
        },
      });

      return;
    }

    const date = new Date();
    navigate(`/edit/${dayjs(date).format('YYYY-MM-DD')}`);
  }

  switch (page) {
    case 'time-slot':
      return (
        <SelectTimeSlot onSelect={onSelectTimeSlot} onGoBack={onGoBack} />
      );
    case 'qr-code':
      return (
        <>
          <QrScanner
            onGoBack={onGoBack}
            onQrCode={onQrCodeReady}
          />

          {alert.render()}
        </>
      );
  }
}
