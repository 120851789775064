import dayjs from 'dayjs';
import TimezonePlugin from 'dayjs/plugin/timezone';
import UtcPlugin from 'dayjs/plugin/utc';
import React from 'react';
import ReactDOM from 'react-dom';
import Swiper, { Navigation, Pagination } from 'swiper';

import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'swiper/swiper.min.css';


dayjs.extend(TimezonePlugin);
dayjs.extend(UtcPlugin);

Swiper.use([
  Navigation,
  Pagination,
]);

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
