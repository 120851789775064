import { useRef, useState } from 'react';

import './DialogMenu.css';


export default function DialogMenu(props: {
  title: string;
  buttons: Array<{
    role: string;
    text: string;
  }>;

  onDismiss: (btnRole: string) => void;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  function onClickBackdrop(event: React.MouseEvent<HTMLDivElement>) {
    if (event.target === containerRef.current) {
      props.onDismiss('cancel');
    }
  }

  const buttons = props.buttons.map((it, idx) => (
    <button
      key={idx}
      className="dialog-button"
      onClick={() => props.onDismiss(it.role)}
    >{it.text}</button>
  ));

  return (
    <div ref={containerRef} className="dialog-container" onClick={onClickBackdrop}>
      <div className="dialog-dialog">
        <div className="dialog-title">{props.title}</div>
        <div className="dialog-buttons">{buttons}</div>
      </div>
    </div>
  );
}


export function useDialogMenu() {
  const [dialogMenuProps, setDialogMenuProps] = useState<null | Parameters<typeof DialogMenu>[0]>(null);

  return {
    show: (props: Parameters<typeof DialogMenu>[0]) => setDialogMenuProps(props),
    hide: () => setDialogMenuProps(null),

    render: () => dialogMenuProps !== null
      ? (
        <DialogMenu {...dialogMenuProps} />
      )
      : null,
  };
}
