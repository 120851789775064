import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../../components/Layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import slotsService from '../../services/SlotsService';

import './PresetCreate.css';


export default function PresetCreate() {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [isLoading, setLoading] = useState(false);

  async function onSubmit() {
    setLoading(true);
    try {
      await slotsService.createPreset(name);
    } catch (e) {
      // TODO: Handle error.
      setLoading(false);
      return;
    }

    navigate(`/admin/slots/preset/${name}`, {
      replace: true,
    });
  }

  return (
    <Layout title="Nou grup predefinit" withBackButton>
      <div className="presetcreate-item">
        <span className="presetcreate-item-label">Nom del grup:</span>
        <input
          type="text"
          value={name}
          onChange={event => setName(event.target.value)}
        />
      </div>

      <button className="presetcreate-button" onClick={onSubmit}>Crear el grup</button>

      {
        isLoading
          ? (
            <LoadingSpinner />
          )
          : null
      }
    </Layout>
  );
}
