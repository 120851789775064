import React from 'react';


export default function Slide3() {
  return (
    <>
      <div className="tour-description">
        <i className="bi-calendar3" />
      </div>

      <div className="tour-step">
        Tria l'hora d'entrega
      </div>
    </>
  );
}
