import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAlert } from '../../components/Alert';
import Layout from '../../components/Layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import slotsService from '../../services/SlotsService';

import './PresetApply.css';


export default function PresetApply() {
  const alert = useAlert();
  const params = useParams<'name'>();
  const navigate = useNavigate();

  const [date, setDate] = useState<Date | null>(null);
  const [isLoading, setLoading] = useState(false);

  function onSubmit() {
    if (date === null)
      return;

    alert.show({
      title: 'Confirmació',
      type: 'confirm',
      message: 'Es substituiran les franges ja existents. Segur que vols '
        + 'continuar?',
      onDismiss: async role => {
        alert.hide();
        if (role === 'ok') {
          setLoading(true);
          try {
            await slotsService.applyPreset(params.name!, date!);
          } catch (e) {
            // TODO: Handle error.
            setLoading(false);
            return;
          }

          navigate(`/admin/slots`, {
            replace: true,
          });
        }
      },
    });
  }

  return (
    <Layout title="Aplica el grup" withBackButton>
      <div className="presetapply-item">
        <span className="presetapply-item-label">Dia de destí:</span>
        <input
          type="date"
          onChange={event => setDate(event.target.valueAsDate!)}
        />
      </div>

      <button className="presetapply-button" onClick={onSubmit}>Aplicar el grup</button>

      {alert.render()}
      {
        isLoading
          ? (
            <LoadingSpinner />
          )
          : null
      }
    </Layout>
  );
}
