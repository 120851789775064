import { useEffect, useState } from 'react';

import Layout from '../components/Layout';
import LoadingSpinner from '../components/LoadingSpinner';
import authService from '../services/AuthService';

import './Profile.css';


export default function Profile() {
  const [isLoading, setLoading] = useState(true);

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [name, setName] = useState('');
  const [surname1, setSurname1] = useState('');
  const [surname2, setSurname2] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');

  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [passwordRepeatError, setPasswordRepeatError] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);
  const [surname1Error, setSurname1Error] = useState<string | null>(null);
  const [surname2Error, setSurname2Error] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [addressError, setAddressError] = useState<string | null>(null);

  useEffect(() => {
    let currentUser = authService.currentUser!;

    setName(currentUser.name);
    setSurname1(currentUser.surname1);
    setSurname2(currentUser.surname2 ?? '');
    setEmail(currentUser.email);
    setAddress(currentUser.address);

    setLoading(false);
  }, []);

  async function onSubmit() {
    let isValid = true;
    isValid = validatePassword() && isValid;
    isValid = validatePasswordRepeat(false) && isValid;
    isValid = validateName() && isValid;
    isValid = validateSurname1() && isValid;
    isValid = validateSurname2() && isValid;
    isValid = validateEmail() && isValid;
    isValid = validateAddress() && isValid;
    if (!isValid) {
      return;
    }

    setLoading(true);
    try {
      await authService.updateInfo({
        password: password.length === 0 ? undefined : password,
        name,
        surname1,
        surname2,
        email,
        address,
      })
    } finally {
      setLoading(false);
    }
  }

  function validatePassword() {
    setPasswordError(null);
    validatePasswordRepeat(true);
    return true;
  }

  function validatePasswordRepeat(fromPasswordValidator: boolean) {
    if (fromPasswordValidator) {
      if (passwordRepeatError === 'Les contrasenyes no coincideixen.') {
        if (passwordRepeat === password) {
          setPasswordRepeatError(null);
          return true;
        }
      }

      return false;
    }

    if (passwordRepeat !== password) {
      setPasswordRepeatError('Les contrasenyes no coincideixen.');
    } else {
      setPasswordRepeatError(null);
      return true;
    }

    return false;
  }

  function validateName() {
    if (name.length === 0) {
      setNameError('El nom no pot estar buit.');
    } else {
      setNameError(null);
      return true;
    }

    return false;
  }

  function validateSurname1() {
    if (surname1.length === 0) {
      setSurname1Error('El primer cognom no pot estar buit.');
    } else {
      setSurname1Error(null);
      return true;
    }

    return false;
  }

  function validateSurname2() {
    setSurname2Error(null);
    return true;
  }

  function validateEmail() {
    if (email.length === 0) {
      setEmailError('L\'adreça electrònica no pot estar buida.');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setEmailError('L\'adreça electrònica no és vàlida.');
    } else {
      setEmailError(null);
      return true;
    }

    return false;
  }

  function validateAddress() {
    if (address.length === 0) {
      setAddressError('L\'adreça no pot estar buida.');
    } else {
      setAddressError(null);
      return true;
    }

    return false;
  }

  return (
    <Layout title="Perfil d'usuari" withBackButton>
      <div className="profile-item">
        <span className="profile-item-label">Contrasenya</span>
        <input
          value={password}
          onChange={event => setPassword(event.target.value)}
          onBlur={validatePassword}
          type="password"
        />
        {
          passwordError !== null
            ? (
              <span className="profile-item-error">{passwordError}</span>
            )
            : null
        }
      </div>

      <div className="profile-item">
        <span className="profile-item-label">Repeteix la contrasenya</span>
        <input
          value={passwordRepeat}
          onChange={event => setPasswordRepeat(event.target.value)}
          onBlur={() => validatePasswordRepeat(false)}
          type="password"
        />
        {
          passwordRepeatError !== null
            ? (
              <span className="profile-item-error">{passwordRepeatError}</span>
            )
            : null
        }
      </div>

      <div className="profile-item">
        <span className="profile-item-label">Nom</span>
        <input
          value={name}
          onChange={event => setName(event.target.value)}
          onBlur={validateName}
        />
        {
          nameError !== null
            ? (
              <span className="profile-item-error">{nameError}</span>
            )
            : null
        }
      </div>

      <div className="profile-item">
        <span className="profile-item-label">Primer cognom</span>
        <input
          value={surname1}
          onChange={event => setSurname1(event.target.value)}
          onBlur={validateSurname1}
        />
        {
          surname1Error !== null
            ? (
              <span className="profile-item-error">{surname1Error}</span>
            )
            : null
        }
      </div>

      <div className="profile-item">
        <span className="profile-item-label">Segon cognom</span>
        <input
          value={surname2}
          onChange={event => setSurname2(event.target.value)}
          onBlur={validateSurname2}
        />
        {
          surname2Error !== null
            ? (
              <span className="profile-item-error">{surname2Error}</span>
            )
            : null
        }
      </div>

      <div className="profile-item">
        <span className="profile-item-label">Adreça electrònica</span>
        <input
          value={email}
          onChange={event => setEmail(event.target.value)}
          onBlur={validateEmail}
        />
        {
          emailError !== null
            ? (
              <span className="profile-item-error">{emailError}</span>
            )
            : null
        }
      </div>

      <div className="profile-item">
        <span className="profile-item-label">Adreça</span>
        <textarea
          value={address}
          onChange={event => setAddress(event.target.value)}
          onBlur={validateAddress}
        />
        {
          addressError !== null
            ? (
              <span className="profile-item-error">{addressError}</span>
            )
            : null
        }
      </div>

      <button className="profile-button" onClick={onSubmit}>Actualitzar</button>

      {
        isLoading
          ? (
            <LoadingSpinner />
          )
          : null
      }
    </Layout>
  );
}
