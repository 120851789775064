import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useAlert } from '../components/Alert';
import Layout from '../components/Layout';
import LoadingSpinner from '../components/LoadingSpinner';
import SelectTimeSlot from '../components/SelectTimeSlot';
import ordersService, { Shop } from '../services/OrdersService';
import QrScanner from './QrScanner';

import './OrderEdit.css';


export default function OrderEdit() {
  const alert = useAlert();
  const navigate = useNavigate();
  const params = useParams<'orderId'>();

  const [isLoading, setLoading] = useState(true);
  const [page, setPage] = useState<null | 'select-slot' | 'qr-code'>(null);
  const [timeSlot, setTimeSlot] = useState<null | Date>(null);
  const [shops, setShops] = useState<Array<Shop>>([]);

  async function refreshOrderDetails() {
    setLoading(true);
    try {
      const orderDetails = await ordersService.fetchOrderDetails(params.orderId!);
      setShops(orderDetails!.shops);
    } catch (e) {
      alert.show({
        type: 'retry',
        title: 'Error',
        message: 'Hi ha hagut un error al intentar obtenir les dades de la comanda.',
        onDismiss: () => {
          alert.hide();
          refreshOrderDetails();
        },
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    refreshOrderDetails();
  }, []);

  function onClickAdd() {
    if (shops.length === 0) {
      setPage('select-slot');
      return;
    }

    setPage('qr-code');
  }

  async function onSlotSelected(timeSlot: Date) {
    setPage('qr-code');
    setTimeSlot(timeSlot);
  }

  async function onClickDel(shopId: string) {
    setLoading(true);
    try {
      await ordersService.modifyOrder(params.orderId!, {
        timeSlot: shops.length === 1 ? null : undefined,
        shops: {
          del: [shopId],
        },
      });
      await refreshOrderDetails();
    } catch (e) {
      alert.show({
        type: 'message',
        title: 'Error',
        message: 'No s\'ha pogut esborrar la parada. Torna-ho a intentar més tard.',
        onDismiss: () => alert.hide(),
      });
    } finally {
      setLoading(false);
    }
  }

  async function onClickFinish() {
    if (shops.length === 0) {
      return;
    }

    setLoading(true);
    try {
      await ordersService.sendOrder(params.orderId!);
    } catch (e) {
      alert.show({
        type: 'message',
        title: 'Error',
        message: 'No s\'ha pogut enviar la comanda. Torna-ho a intentar més tard.',
        onDismiss: () => alert.hide(),
      });
    } finally {
      setLoading(false);
    }

    navigate('/');
  }

  async function onQrCode(shopId: string) {
    setLoading(true);
    try {
      await ordersService.modifyOrder(params.orderId!, {
        timeSlot: shops.length === 0 ? timeSlot! : undefined,
        shops: {
          add: [shopId],
        },
      });
    } catch (e) {
      alert.show({
        type: 'message',
        title: 'Error',
        message: 'No s\'ha pogut afegir la parada. Torna-ho a intentar més tard.',
        onDismiss: () => alert.hide(),
      })
    } finally {
      setLoading(false);

      setPage(null);
      await refreshOrderDetails();
    }
  }

  if (page === 'select-slot') {
    return (
      <SelectTimeSlot
        onSelect={onSlotSelected}
        onGoBack={() => setPage(null)}
      />
    );
  }

  if (page === 'qr-code') {
    return (
      <QrScanner
        onGoBack={() => setPage('select-slot')}
        onQrCode={onQrCode}
      />
    );
  }

  return (
    <Layout title="Comanda d'avui" withBackButton>
      <button className="orderedit-add-button" onClick={onClickAdd}>
        Afegir punt de recollida
      </button>

      <div className="orderedit-shops">
        {
          shops.map((it, idx) => (
            <div key={idx} className="orderedit-shop">
              <div className="orderedit-shop-row">
                <div>{it.name}</div>
                <i className="bi-trash" onClick={() => onClickDel(it.id)} />
              </div>
              {
                idx + 1 < shops.length
                  ? <hr />
                  : null
              }
            </div>
          ))
        }

        {
          shops.length === 0
            ? (
              <div className="orderedit-shops-empty">
                No hi ha cap punt de recollida.
              </div>
            )
            : null
        }
      </div>

      <button className="orderedit-finish-button" onClick={onClickFinish}>
        Finalitzar
      </button>

      {alert.render()}
      {
        isLoading
          ? <LoadingSpinner />
          : null
      }
    </Layout>
  );
}
