import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import LoadingSpinner from './components/LoadingSpinner';
import AdminSlots from './pages/admin/AdminSlots';
import PresetApply from './pages/admin/PresetApply';
import PresetCreate from './pages/admin/PresetCreate';
import PresetEdit from './pages/admin/PresetEdit';
import SlotCreate from './pages/admin/SlotCreate';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import AdminOrderDetails from './pages/delivery/OrderDetails';
import AdminPendingOrders from './pages/delivery/PendingOrders';
import AdminReceiverDetails from './pages/delivery/ReceiverDetails';
import DeliveredOrders from './pages/DeliveredOrders';
import Home from './pages/Home';
import OrderCreate from './pages/OrderCreate';
import OrderEdit from './pages/OrderEdit';
import OrderView from './pages/OrderView';
import PendingOrders from './pages/PendingOrders';
import Profile from './pages/Profile';
import { useCurrentUser } from './services/AuthService';

import './App.css';


export default function App() {
  const currentUser = useCurrentUser();

  if (currentUser === undefined) {
    return (
      <LoadingSpinner />
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        {
          currentUser === null
            ? (
              <>
                <Route path="/auth/sign-in" element={<SignIn />} />
                <Route path="/auth/sign-up" element={<SignUp />} />

                <Route path="*" element={<Navigate to="/auth/sign-in" />} />
              </>
            )
            : (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/pending" element={<PendingOrders />} />
                <Route path="/delivered" element={<DeliveredOrders />} />

                <Route path="/new-order" element={<OrderCreate />} />
                <Route path="/edit/:orderId" element={<OrderEdit />} />
                <Route path="/view/:orderId" element={<OrderView />} />

                <Route path="/profile" element={<Profile />} />
                <Route path="*" element={<Navigate to="/" />} />

                {
                  currentUser!.userType === 'admin'
                    ? (
                      <>
                        <Route path="/admin/slots" element={<AdminSlots />} />
                        <Route path="/admin/slots/new-preset" element={<PresetCreate />} />
                        {/* <Route path="/admin/slots/new-slot" element={<SlotCreate mode="full" />} /> */}
                        <Route path="/admin/slots/preset/:name" element={<PresetEdit />} />
                        <Route path="/admin/slots/preset/:name/apply" element={<PresetApply />} />
                        <Route path="/admin/slots/preset/:name/new-slot" element={<SlotCreate mode="time-only" />} />
                      </>
                    )
                    : null
                }

                {
                  currentUser!.userType === 'admin' || currentUser!.userType === 'delivery'
                    ? (
                      <>
                        <Route path="/delivery" element={<AdminPendingOrders />} />
                        <Route path="/delivery/:nif/:orderId" element={<AdminOrderDetails />} />
                        <Route path="/delivery/:nif/:orderId/details" element={<AdminOrderDetails readOnly />} />
                        <Route path="/delivery/:nif/:orderId/deliver" element={<AdminReceiverDetails />} />
                      </>
                    )
                    : null
                }
              </>
            )
        }
      </Routes>
    </BrowserRouter>
  );
}
