import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../../components/Layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import Title from '../../components/Title';
import { useAdminExpiredOrders, useAdminPendingOrders } from '../../services/OrdersService';


export default function PendingOrders() {
  const navigate = useNavigate();
  const pendingOrders = useAdminPendingOrders();
  const expiredOrders = useAdminExpiredOrders();

  return (
    <Layout
      title="Comandes pendents"
      withBackButton
      backButtonTo="/"
    >
      {
        pendingOrders?.map((it, idx) => (
          <div
            key={idx}
            className="orderslist-item"
            onClick={() => navigate(`/delivery/${it.nif}/${dayjs(it.timeSlot).format('YYYY-MM-DD')}`)}
          >
            {it.name} {it.surname1} {it.surname2}<br />
            {it.address}<br />
            {it.timeSlot.toLocaleTimeString()}
            <hr className="orderslist-hr" />
          </div>
        ))
      }
      {
        pendingOrders?.length === 0
          ? (
            <>La llista és buida.</>
          )
          : null
      }

      <div style={{ paddingBottom: 16 }} />
      <Title text="Comandes obertes i expirades" />

      {
        expiredOrders?.map((it, idx) => (
          <div
            key={idx}
            className="orderslist-item"
            onClick={() => navigate(`/delivery/${it.nif}/${dayjs(it.timeSlot).format('YYYY-MM-DD')}/details`)}
          >
            {it.name} {it.surname1} {it.surname2}<br />
            {it.address}<br />
            {it.timeSlot.toLocaleString()}<br />
            {it.phone}
            <hr className="orderslist-hr" />
          </div>
        ))
      }
      {
        expiredOrders?.length === 0
          ? (
            <>La llista és buida.</>
          )
          : null
      }

      {
        pendingOrders === undefined
          ? <LoadingSpinner />
          : null
      }
    </Layout>
  );
}
