import React from 'react';


export default function Slide4() {
  return (
    <>
      <div className="tour-description">
        <i className="bi-house-door" />
      </div>

      <div className="tour-step">
        Gaudeix, no carreguis amb la compra
      </div>
    </>
  );
}
