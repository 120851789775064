import { Result } from '@zxing/library';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router';

import { useAlert } from '../components/Alert';
import Header from '../components/Header';
import LoadingSpinner from '../components/LoadingSpinner';
import QrScannerComponent, { QrScannerApi } from '../components/QrScanner';
import Title from '../components/Title';
import ordersService from '../services/OrdersService';
import shopsService, { ShopInfo } from '../services/ShopsService';

import './QrScanner.css';


export default function QrScanner(props: {
  onGoBack?: () => void;
  onQrCode?: (shopId: string) => Promise<void>;
}) {
  const alert = useAlert();
  const params = useParams<'orderId'>();

  const qrScannerRef = useRef<QrScannerApi>(null);

  const [isLoading, setLoading] = useState(false);
  const [isManual, setManual] = useState(false);
  const [publicShops, setPublicShops] = useState<Array<ShopInfo> | null>(null);

  async function onQrCode(result: Result) {
    qrScannerRef.current!.stopScanning();
    setLoading(true);

    const qrText = result.getText();
    const matches = qrText.match(/^https:\/\/tigapp\.vilamercat\.cat\/landing\/(.*?)(?:\.html)?$/);
    if (matches !== null && matches.length > 0) {
      const shopId = matches[1];
      if (props.onQrCode !== undefined) {
        await props.onQrCode(shopId);
        setLoading(false);
        return;
      }

      try {
        await ordersService.modifyOrder(params.orderId!, {
          shops: {
            add: [shopId],
          },
        });
      } catch (e) {
        alert.show({
          type: 'confirm',
          title: 'Error',
          message: 'No s\'ha pogut afegir la parada. Reintentar?',
          onDismiss: btn => {
            alert.hide();
            if (btn === 'ok') {
              onQrCode(result);
              return;
            }
          },
        });
      } finally {
        setLoading(false);
      }

      return;
    }

    setLoading(false);
    qrScannerRef.current!.startScanning();
  }

  async function onClickManualSelect() {
    setLoading(true);
    setPublicShops(await shopsService.fetchPublicShops());
    setLoading(false);

    setManual(true);
  }

  return (
    <div className="qrscanner-page-root-container">
      <Header withBackButton backButtonTo={props.onGoBack} />

      {
        isManual === false
          ? (
            <>
              <div className="qrscanner-page-container">
                <QrScannerComponent
                  ref={qrScannerRef}
                  onQrCode={onQrCode}
                  onNoCamera={onClickManualSelect}
                />
              </div>

              <div className="qrscanner-page-overlay">
                <button
                  className="orderedit-add-button"
                  onClick={onClickManualSelect}
                >Sel·lecció manual</button>
              </div>
            </>
          )
          : (
            <>
              <div className="qrscanner-page-manual">
                <Title text="Tria una botiga" />

                <div className="qrscanner-page-manual-list">
                  {
                    publicShops?.map((it, idx) => (
                      <div
                        key={idx}
                        className="adminslots-slot-item"
                        onClick={() => props.onQrCode?.(it.textId)}
                      >
                        {it.name}
                      </div>
                    ))
                  }
                </div>
              </div>

              <div className="qrscanner-page-overlay">
                <button
                  className="orderedit-add-button"
                  onClick={onClickManualSelect}
                >Sel·lecció manual</button>
              </div>
            </>
          )
      }

      {alert.render()}
      {
        isLoading
          ? <LoadingSpinner />
          : null
      }
    </div>
  );
}
