import React from 'react';


export default function Slide2() {
  return (
    <>
      <div className="tour-description">
        <i className="bi-qr-code" />
      </div>

      <div className="tour-step">
        Escaneja el codi QR de la botiga associada
      </div>
    </>
  );
}
