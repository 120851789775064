import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router';

import { Order } from '../services/OrdersService';
import Layout from './Layout';

import './OrdersList.css';


export default function OrdersList(props: {
  title: string;
  navBarTab?: 'pending' | 'delivered';
  orders: Array<Order>;
}) {
  const navigate = useNavigate();

  return (
    <Layout
      title={props.title}
      withNavBar={props.navBarTab !== undefined}
      navBarTab={props.navBarTab}
      withBackButton
      backButtonTo="/"
    >
      {
        props.orders.map((it, idx) => (
          <div
            key={idx}
            className="orderslist-item"
            onClick={() => navigate(`/view/${dayjs(it.date).format('YYYY-MM-DD')}`)}
          >
            {dayjs(it.date).format('DD/MM/YYYY')}
            <hr className="orderslist-hr" />
          </div>
        ))
      }
      {
        props.orders.length === 0
          ? (
            <>La llista és buida.</>
          )
          : null
      }
    </Layout>
  );
}
