import { ReportHandler } from 'web-vitals';


export default async function reportWebVitals(onPerfEntry?: ReportHandler) {
  if (onPerfEntry !== undefined) {
    const webVitals = await import('web-vitals');
    
    webVitals.getCLS(onPerfEntry!);
    webVitals.getFID(onPerfEntry!);
    webVitals.getFCP(onPerfEntry!);
    webVitals.getLCP(onPerfEntry!);
    webVitals.getTTFB(onPerfEntry!);
  }
}
