import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../../components/Layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useAdminOrderDetails } from '../../services/OrdersService';


export default function OrderDetails(props: { readOnly?: boolean }) {
  const params = useParams<'nif' | 'orderId'>();
  const navigate = useNavigate();
  const orderDetails = useAdminOrderDetails(params.nif!, params.orderId!);

  return (
    <Layout
      title="Detalls de la comanda"
      withBackButton
    >
      {
        orderDetails !== undefined
          ? (
            <>
              Usuari: {orderDetails!.name} {orderDetails!.surname1} {orderDetails!.surname2}<br />
              NIF: {orderDetails!.nif}<br />
              Telèfon: {orderDetails.phone}<br />
              Adreça: {orderDetails.address}<br />
              Franja: {dayjs(orderDetails.timeSlot).format('DD/MM/YYYY HH:mm:ss')}<br />
              <br />
              Botigues:<br />
              <ul>
                {
                  orderDetails.shops.map(it => (
                    <li>
                      Nom: {it.name}<br />
                      Adreça: {it.address}
                    </li>
                  ))
                }
              </ul>

              {
                props.readOnly !== true
                  ? (
                    <button
                      className="presetcreate-button"
                      onClick={() => navigate(`/delivery/${params.nif}/${params.orderId}/deliver`)}
                    >Confirmar entrega</button>
                  )
                  : null
              }
            </>
          )
          : <LoadingSpinner />
      }
    </Layout>
  );
}
