import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import Layout from '../../components/Layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import authService from '../../services/AuthService';

import './auth.css';


export default function SignIn() {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  async function onSubmit() {
    let isValid = true;
    isValid = validatePhone() && isValid;
    isValid = validatePassword() && isValid;
    if (!isValid) {
      return;
    }

    setLoading(true);
    try {
      if (!await authService.signIn({ phone, password })) {
        setPhoneError('El telèfon/NIF o la contrasenya són incorrectes.');
        setPasswordError('El telèfon/NIF o la contrasenya són incorrectes.');
      }
    } finally {
      setLoading(false);
    }
  }

  function validatePhone() {
    if (phone.length === 0) {
      setPhoneError('El telèfon no pot estar buit.');
    } else {
      setPhoneError(null);
      return true;
    }

    return false;
  }

  function validatePassword() {
    if (password.length === 0) {
      setPasswordError('La contrasenya no pot estar buida.');
    } else {
      setPasswordError(null);
      return true;
    }

    return false;
  }

  return (
    <Layout title="Entra">
      <div className="auth-item">
        <span className="auth-item-label">Telèfon</span>
        <input
          value={phone}
          onChange={event => setPhone(event.target.value)}
          onBlur={validatePhone}
        />
        {
          phoneError !== null
            ? (
              <span className="auth-item-error">{phoneError}</span>
            )
            : null
        }
      </div>

      <div className="auth-item">
        <span className="auth-item-label">Contrasenya</span>
        <input
          value={password}
          onChange={event => setPassword(event.target.value)}
          onBlur={validatePassword}
          type="password"
        />
        {
          passwordError !== null
            ? (
              <span className="auth-item-error">{passwordError}</span>
            )
            : null
        }
      </div>

      <button className="auth-button" onClick={onSubmit}>Entra</button>
      <button className="auth-button" onClick={() => navigate('/auth/sign-up')}>
        Porta'm al registre
      </button>

      {
        isLoading
          ? (
            <LoadingSpinner />
          )
          : null
      }
    </Layout>
  );
}
