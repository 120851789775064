import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import './index.css';
import Slide1 from './Slide1';
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide4 from './Slide4';
import Slide5 from './Slide5';


export default function Tour() {
  const pageRef = useRef<HTMLDivElement>(null);

  return (
    <div className="tour-container">
      <Swiper
        className="tour-swiper"
        pagination={{
          type: 'fraction',
          el: pageRef.current,
        }}
      >
        <SwiperSlide>
          <Slide1 />
        </SwiperSlide>
        <SwiperSlide>
          <Slide2 />
        </SwiperSlide>
        <SwiperSlide>
          <Slide3 />
        </SwiperSlide>
        <SwiperSlide>
          <Slide4 />
        </SwiperSlide>
        <SwiperSlide>
          <Slide5 />
        </SwiperSlide>

        <div ref={pageRef} className="tour-index" />
      </Swiper>
    </div>
  );
}
