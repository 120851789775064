import React from 'react';

import './LoadingSpinner.css';


export default function LoadingSpinner() {
  return (
    <div className="loadingspinner-container">
      <div className="loadingspinner-dialog">
        <div className="loadingspinner-spinner" />
      </div>
    </div>
  );
}
