import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../../components/Layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import slotsService from '../../services/SlotsService';

import './SlotCreate.css';


export default function SlotCreate(props: {
  mode: 'full' | 'time-only',
}) {
  const params = useParams<'name'>();
  const navigate = useNavigate();

  const [date, setDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [maxOrders, setMaxOrders] = useState<number>(1);
  const [isLoading, setLoading] = useState(false);

  async function onSubmit() {
    if (startTime === null || endTime === null)
      return;
    if (props.mode === 'full' && date === null)
      return;

    setLoading(true);
    try {
      let timeStamp = 0;
      if (props.mode === 'full') {
        timeStamp = date!.getTime();
        timeStamp += date!.getTimezoneOffset() * 60000;
      }

      const tStartTime = new Date(timeStamp + startTime.getTime());
      const tEndTime = new Date(timeStamp + endTime.getTime());

      switch (props.mode) {
        case 'full':
          // TODO: Add the timeSlot.
          break;
        case 'time-only':
          // TODO: Add the timeSlot.
          await slotsService.modifyPreset(params.name!, {
            add: [
              {
                startTime: tStartTime,
                endTime: tEndTime,
                maxOrders,
              },
            ],
          });
          break;
      }
    } catch (e) {
      // TODO: Handle error.
      setLoading(false);
      return;
    }

    navigate(-1);
  }

  return (
    <Layout title="Nova franja" withBackButton>
      {
        props.mode === 'full'
          ? (
            <div className="slotcreate-item">
              <span className="slotcreate-item-label">Data</span>
              <input
                type="date"
                onChange={event => setDate(event.target.valueAsDate)}
              />
            </div>
          )
          : null
      }
      <div className="slotcreate-item">
        <span className="slotcreate-item-label">Hora d'inici</span>
        <input
          type="time"
          onChange={event => setStartTime(event.target.valueAsDate)}
        />
      </div>

      <div className="slotcreate-item">
        <span className="slotcreate-item-label">Hora final</span>
        <input
          type="time"
          onChange={event => setEndTime(event.target.valueAsDate)}
        />
      </div>

      <div className="slotcreate-item">
        <span className="slotcreate-item-label">Màxim de comandes</span>
        <input
          type="number"
          value={maxOrders}
          min={1}
          onChange={event => setMaxOrders(event.target.valueAsNumber)}
        />
      </div>

      <button className="slotcreate-button" onClick={onSubmit}>Crear la franja</button>

      {
        isLoading
          ? (
            <LoadingSpinner />
          )
          : null
      }
    </Layout>
  );
}
