import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAlert } from '../../components/Alert';
import { useDialogMenu } from '../../components/DialogMenu';
import Layout from '../../components/Layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import slotsService, { TimeSlot } from '../../services/SlotsService';

import './AdminSlots.css';


export default function AdminSlots() {
  const alert = useAlert();
  const dialogMenu = useDialogMenu();
  const navigate = useNavigate();

  const [presets, setPresets] = useState<Array<string>>([]);
  const [timeSlots, setTimeSlots] = useState<Array<TimeSlot>>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    new Promise<void>(async (resolve, reject) => {
      try {
        const [timeSlots, presets] = await Promise.all([
          slotsService.fetchAllTimeSlots(),
          slotsService.fetchTimeSlotPresets(),
        ]);

        setTimeSlots(timeSlots);
        setPresets(presets);
      } catch (e) {
        reject(e);
        return;
      }

      resolve();
    })
      .then(() => setLoading(false))
      .catch(() => {
        // TODO: Handle error.
      });
  }, []);

  function onClickDeletePreset(presetName: string) {
    alert.show({
      title: 'Confirmació',
      message: 'Segur que vols esborrar aquest grup predefinit?',
      type: 'confirm',
      onDismiss: async role => {
        alert.hide();
        if (role === 'ok') {
          setLoading(true);
          try {
            await slotsService.deletePreset(presetName);
          } catch (e) {
            // TODO: Handle error.
            setLoading(false);
            return;
          }

          setPresets(await slotsService.fetchTimeSlotPresets());
          setLoading(false);
        }
      },
    });
  }

  function onContextMenuPreset(presetName: string) {
    dialogMenu.show({
      title: 'Menú',
      buttons: [
        {
          text: 'Editar',
          role: 'edit',
        },
        {
          text: 'Aplicar',
          role: 'apply',
        },
        {
          text: 'Esborrar',
          role: 'delete',
        },
      ],
      onDismiss: role => {
        dialogMenu.hide();
        switch (role) {
          case 'edit':
            navigate(`/admin/slots/preset/${presetName}`);
            break;
          case 'apply':
            navigate(`/admin/slots/preset/${presetName}/apply`);
            break;
          case 'delete':
            onClickDeletePreset(presetName);
            break;
          default:
            break;
        }
      },
    })
  }

  const timeSlotsElements: Array<any> = [];
  let lastDate: string | null = null;
  for (const timeSlot of timeSlots) {
    if (timeSlot.startTime.toDateString() !== lastDate)
      timeSlotsElements.push(
        <div key={timeSlotsElements.length} className="adminslots-slot-section">
          {dayjs(timeSlot.startTime).format('DD/MM/YYYY')}
          <hr />
        </div>
      );

    timeSlotsElements.push(
      <div key={timeSlotsElements.length} className="adminslots-slot-item">
        {dayjs(timeSlot.startTime).format('HH:mm')} - {dayjs(timeSlot.endTime).format('HH:mm')} ({timeSlot.remainingOrders})
      </div>
    );
    lastDate = timeSlot.startTime.toDateString();
  }

  return (
    <Layout title="Administració - Franges" withBackButton>
      <div className="adminslots-section">
        <div className="adminslots-section-header">
          <div className="adminslots-section-header-row">
            Grups predefinits
            <i className="bi-plus-lg" onClick={() => navigate('/admin/slots/new-preset')} />
          </div>
          <hr className="adminslots-hr" />
        </div>

        {
          presets.length === 0
            ? (
              "No hi ha cap grup predefinit."
            )
            : (
              presets.map((it, idx) => (
                <div
                  key={idx}
                  className="adminslots-item"
                  onClick={event => {
                    if ((event.target as any).tagName === 'I')
                      return;

                    navigate(`/admin/slots/preset/${it}`);
                  }}
                  onContextMenu={event => {
                    event.preventDefault();
                    onContextMenuPreset(it);
                  }}
                >
                  {it}
                  <i className="bi-trash" onClick={() => onClickDeletePreset(it)} />
                </div>
              ))
            )
        }
      </div>

      <div className="adminslots-section">
        <div className="adminslots-section-header">
          <div className="adminslots-section-header-row">
            Franges d'entrega
            {/* <i className="bi-plus-lg" onClick={() => navigate('/admin/slots/new-slot')} /> */}
          </div>
          <hr className="adminslots-hr" />
        </div>

        {timeSlotsElements}
      </div>

      {alert.render()}
      {dialogMenu.render()}
      {
        isLoading
          ? (
            <LoadingSpinner />
          )
          : null
      }
    </Layout>
  );
}
