import React from 'react';

import './Title.css';


export default function Title(props: {
  text: string;
}) {
  return (
    <div>
      <h1 className="title-h1">{props.text}</h1>
      <hr className="title-hr" />
    </div>
  );
}
