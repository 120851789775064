import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Observer, Subject, Subscription } from 'rxjs';

import config from '../config';
import authService from './AuthService';


export type Order = {
  date: Date;
  status: 'open' | 'pending' | 'delivered';
  timeSlot?: Date | null;
};

export type Shop = {
  id: string;
  name: string;
  address: string;
};

export type OrderDetails = Order & {
  shops: Array<Shop>;
};

export type AdminOrder = {
  nif: string;
  name: string;
  surname1: string;
  surname2?: string;
  timeSlot: Date;
  address: string;
  phone: string;
};

export type Event = Array<Order> | undefined;


class OrdersService {
  private eventDispatcher: Subject<Event>;

  private orders: Array<Order> | undefined;

  public constructor() {
    this.eventDispatcher = new Subject();
    this.orders = undefined;

    authService.subscribe({
      next: currentUser => {
        if (currentUser !== null) {
          this.refreshOrders();
        } else {
          this.orders = undefined;
        }
      }
    });
  }

  public async fetchExpiredOrders(): Promise<Array<AdminOrder>> {
    const res = await fetch(
      new URL('orders/expired', config.baseUrl).toString(),
      {
        credentials: 'same-origin',
      },
    );
    if (res.status === 200) {
      const obj = (await res.json())['orders'];
      return obj.map((it: any) => ({
        ...it,
        timeSlot: dayjs(it.timeSlot).toDate(),
      }));
    } else {
      throw new Error((await res.json())['error']);
    }
  }

  public async fetchAllPendingOrders(): Promise<Array<AdminOrder>> {
    const res = await fetch(
      new URL('orders/deliver', config.baseUrl).toString(),
      {
        credentials: 'same-origin',
      },
    );
    if (res.status === 200) {
      const obj = (await res.json())['orders'];
      return obj.map((it: any) => ({
        ...it,
        timeSlot: dayjs(it.timeSlot).toDate(),
      }));
    } else {
      throw new Error((await res.json())['error']);
    }
  }

  public async fetchAdminOrderDetails(nif: string, orderId: string) {
    const res = await fetch(
      new URL(`orders/deliver/${nif}/${orderId}`, config.baseUrl).toString(),
      {
        credentials: 'same-origin',
      },
    );
    if (res.status === 200) {
      const obj = (await res.json())['order'];
      return {
        ...obj,
        timeSlot: dayjs(obj.timeSlot).toDate(),
      };
    } else {
      throw new Error((await res.json())['error']);
    }
  }

  public async deliverOrder(nif: string, orderId: string, params: {
    name: string;
    nif: string;
  }) {
    const res = await fetch(
      new URL(`orders/deliver/${nif}/${orderId}`, config.baseUrl).toString(),
      {
        method: 'POST',
        credentials: 'same-origin',
        body: new URLSearchParams({
          receiverName: params.name,
          receiverNif: params.nif,
        }),
      },
    );
    if (res.status === 200) {
      return;
    } else {
      throw new Error((await res.json())['error']);
    }
  }

  public async fetchOrderDetails(orderId: string): Promise<OrderDetails | null> {
    const res = await fetch(
      new URL(`orders/${orderId}`, config.baseUrl).toString(),
      {
        credentials: 'same-origin',
      },
    );
    if (res.status === 200) {
      const obj = (await res.json())['order'];
      return {
        ...obj,
        date: dayjs(obj.date).toDate(),
      };
    } else {
      throw new Error((await res.json())['error']);
    }
  }

  public async createOrder() {
    const res = await fetch(new URL('orders', config.baseUrl).toString(), {
      method: 'POST',
      credentials: 'same-origin',
    });
    if (res.status === 200) {
      await this.refreshOrders();
      return;
    } else {
      const msg = (await res.json())['error'];
      console.log(JSON.stringify(msg, null, 2));
      throw new Error(msg);
    }
  }

  public async modifyOrder(
    orderId: string,
    params: Pick<Order, 'timeSlot'>
      & {
        shops?:
        | Array<string>
        | {
          add?: Array<string>,
          del?: Array<string>,
        }
      }
  ) {
    const urlSearchParams: any = {};
    if (params.timeSlot !== undefined) {
      if (params.timeSlot === null) {
        urlSearchParams.timeSlot = null;
      } else {
        urlSearchParams.timeSlot = dayjs(params.timeSlot).format('YYYY-MM-DD HH:mm:ss');
      }
    }
    if (params.shops !== undefined)
      urlSearchParams.shops = JSON.stringify(params.shops);

    const res = await fetch(new URL(`orders/${orderId}`, config.baseUrl).toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(urlSearchParams),
      credentials: 'same-origin',
    });
    if (res.status === 200) {
      await this.refreshOrders();
      return;
    } else {
      throw new Error((await res.json())['error']);
    }
  }

  public async sendOrder(orderId: string) {
    const res = await fetch(
      new URL(`orders/${orderId}/finalize`, config.baseUrl).toString(),
      {
        method: 'POST',
        credentials: 'same-origin',
      },
    );
    if (res.status === 200) {
      await this.refreshOrders();
      return;
    } else {
      throw new Error((await res.json())['error']);
    }
  }

  public subscribe(observer: Partial<Observer<Event>>): Subscription {
    return this.eventDispatcher.subscribe(observer);
  }

  public async refreshOrders() {
    const res = await fetch(new URL('orders', config.baseUrl).toString(), {
      credentials: 'same-origin',
    });
    if (res.status === 200) {
      this.orders = (await res.json())['orders']
        .map((it: any) => ({
          ...it,
          date: dayjs(it.date).toDate(),
        }));
      this.eventDispatcher.next(this.orders);
    } else {
      throw new Error((await res.json())['error']);
    }
  }

  public async fetchPricing() {
    const res = await fetch(new URL('orders/pricing', config.baseUrl).toString(), {
      credentials: 'same-origin',
    });
    if (res.status === 200) {
      return (await res.json())['pricing'];
    } else {
      throw new Error((await res.json())['error']);
    }
  }

  public get allOrders() {
    return this.orders;
  }

  public get openOrders() {
    return this.orders?.filter(it => it.status === 'open');
  }

  public get pendingOrders() {
    return this.orders?.filter(it => it.status === 'pending');
  }

  public get deliveredOrders() {
    return this.orders?.filter(it => it.status === 'delivered');
  }
}

const ordersService = new OrdersService();
export default ordersService;


export function useAllOrders() {
  const [allOrders, setAllOrders] = useState(ordersService.allOrders);

  useEffect(() => {
    const subscription = ordersService.subscribe({
      next: event => setAllOrders(event),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return allOrders;
}

export function useOpenOrders() {
  const [pendingOrders, setPendingOrders] = useState(ordersService.openOrders);

  useEffect(() => {
    const subscription = ordersService.subscribe({
      next: event => setPendingOrders(event?.filter(it => it.status === 'open')),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return pendingOrders;
}

export function usePendingOrders() {
  const [pendingOrders, setPendingOrders] = useState(ordersService.pendingOrders);

  useEffect(() => {
    const subscription = ordersService.subscribe({
      next: event => setPendingOrders(event?.filter(it => it.status === 'pending')),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return pendingOrders;
}

export function useDeliveredOrders() {
  const [deliveredOrders, setDeliveredOrders] = useState(ordersService.deliveredOrders);

  useEffect(() => {
    const subscription = ordersService.subscribe({
      next: event => setDeliveredOrders(event?.filter(it => it.status === 'delivered')),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return deliveredOrders;
}


export function useAdminExpiredOrders() {
  const [expiredOrderds, setExpiredOrders] = useState<Array<AdminOrder>>();

  useEffect(() => {
    ordersService.fetchExpiredOrders()
      .then(it => setExpiredOrders(it));
  }, []);

  return expiredOrderds;
}

export function useAdminPendingOrders() {
  const [allPendingOrders, setAllPendingOrders] = useState<Array<AdminOrder>>();

  useEffect(() => {
    ordersService.fetchAllPendingOrders()
      .then(it => setAllPendingOrders(it));
  }, []);

  return allPendingOrders;
}

export function useAdminOrderDetails(nif: string, orderId: string) {
  const [orderDetails, setOrderDetails] = useState<AdminOrder & { shops: Array<Omit<Shop, 'id'>> }>();

  useEffect(() => {
    ordersService.fetchAdminOrderDetails(nif, orderId)
      .then(it => setOrderDetails(it));
  }, []);

  return orderDetails;
}
