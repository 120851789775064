import { BrowserQRCodeReader } from '@zxing/browser';
import { Result } from '@zxing/library';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import { useAlert } from './Alert';

import './QrScanner.css';


export type QrScannerApi = {
  startScanning: () => void;
  stopScanning: () => void;
};


function QrScannerImpl(props: {
  onQrCode: (result: Result) => void;
  onNoCamera: () => void;
}, ref: React.Ref<QrScannerApi>) {
  const alert = useAlert();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const scanTask = useRef<null | ReturnType<typeof setInterval>>(null);
  const qrReader = useRef(new BrowserQRCodeReader());
  const userMedia = useRef<null | MediaStream>(null);

  async function startScanning() {
    videoRef.current!.srcObject = userMedia.current!;
    const canvasCtx = canvasRef.current!.getContext('2d')!;

    scanTask.current = setInterval(async () => {
      const videoScale = Math.max(
        videoRef.current!.clientWidth / videoRef.current!.videoWidth,
        videoRef.current!.clientHeight / videoRef.current!.videoHeight,
      );

      const qrBoxSize = {
        w: videoRef.current!.clientWidth / videoScale,
        h: videoRef.current!.clientHeight / videoScale,
      };
      const qrBoxPos = {
        x: (videoRef.current!.videoWidth - qrBoxSize.w) / 2,
        y: (videoRef.current!.videoHeight - qrBoxSize.h) / 2,
      };

      canvasRef.current!.width = qrBoxSize.w;
      canvasRef.current!.height = qrBoxSize.h;
      canvasCtx!.drawImage(
        videoRef.current!,
        qrBoxPos.x,
        qrBoxPos.y,
        qrBoxSize.w,
        qrBoxSize.h,
        0,
        0,
        qrBoxSize.w,
        qrBoxSize.h,
      );

      let scanResult: Result | undefined;
      try {
        scanResult = qrReader.current!.decodeFromCanvas(canvasRef.current!);
      } catch (e) {
      }

      if (scanResult !== undefined) {
        props.onQrCode(scanResult);
      }
    }, 500);
  }

  function stopScanning() {
    if (scanTask.current !== null) {
      clearInterval(scanTask.current);
      scanTask.current = null;
    }
  }

  useImperativeHandle(ref, () => ({ startScanning, stopScanning }));

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        facingMode: 'environment',
      },
    })
      .then(it => {
        userMedia.current = it;
        startScanning();
      })
      .catch(() => {
        alert.show({
          type: 'message',
          title: 'Error',
          message: 'No hem trobat cap càmera disponible.',
          onDismiss: () => {
            alert.hide();
            props.onNoCamera();
          }
        });
        return;
      });

    return () => {
      stopScanning();

      userMedia.current?.getTracks().forEach(it => it.stop());
    }
  }, []);

  return (
    <div>
      <video
        ref={videoRef}
        className="qrscanner-video"
        autoPlay
        playsInline
      >
        <track kind="captions" />
      </video>

      <canvas
        ref={canvasRef}
        className="qrscanner-virtual"
      />

      {alert.render()}
    </div>
  );
}


const QrScanner = forwardRef(QrScannerImpl);
export default QrScanner;
