const isProdEnv = true;

const config = {
  isProdEnv,

  baseUrl: isProdEnv
    ? 'https://tigapp.vilamercat.cat/api/'
    : 'http://localhost:3000/server/',
};

export default config;
