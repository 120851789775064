import React from 'react';
import { useNavigate } from 'react-router';

import './NavBar.css';


export default function NavBar(props: {
  currentTab?: 'pending' | 'delivered',
}) {
  const navigate = useNavigate();

  function onClick(side: 'left' | 'right') {
    switch (side) {
      case 'left':
        if (props.currentTab === 'pending')
          navigate('/');
        else
          navigate('/pending');
        break;
      case 'right':
        if (props.currentTab === 'delivered')
          navigate('/');
        else
          navigate('/delivered');
        break;
    }
  }

  return (
    <div className="navbar-container">
      <button
        className="navbar-button navbar-button-left"
        onClick={() => onClick('left')}
      >
        {
          props.currentTab === 'pending'
            ? <>Tornar</>
            : <>Pendents</>
        }
      </button>
      <button
        className="navbar-button navbar-button-right"
        onClick={() => onClick('right')}
      >
        {
          props.currentTab === 'delivered'
            ? <>Tornar</>
            : <>Fetes</>
        }
      </button>
    </div>
  );
}
