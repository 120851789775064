import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import Layout from '../../components/Layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import authService from '../../services/AuthService';

import './auth.css';


export default function SignUp() {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [name, setName] = useState('');
  const [surname1, setSurname1] = useState('');
  const [surname2, setSurname2] = useState('');
  const [nif, setNif] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [acceptToS, setAcceptToS] = useState(false);

  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [passwordRepeatError, setPasswordRepeatError] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);
  const [surname1Error, setSurname1Error] = useState<string | null>(null);
  const [surname2Error, setSurname2Error] = useState<string | null>(null);
  const [nifError, setNifError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [addressError, setAddressError] = useState<string | null>(null);
  const [acceptToSError, setAcceptToSError] = useState<string | null>(null);

  async function onSubmit() {
    let isValid = true;
    isValid = validatePhone() && isValid;
    isValid = validatePassword() && isValid;
    isValid = validatePasswordRepeat(false) && isValid;
    isValid = validateName() && isValid;
    isValid = validateSurname1() && isValid;
    isValid = validateSurname2() && isValid;
    isValid = validateNif() && isValid;
    isValid = validateEmail() && isValid;
    isValid = validateAddress() && isValid;
    isValid = validateAcceptTos() && isValid;
    if (!isValid) {
      return;
    }

    setLoading(true);
    try {
      if (!await authService.signUp({
        phone,
        password,
        name,
        surname1,
        surname2,
        nif,
        email,
        address,
      })) {
        setPhoneError('Aquest DNI/NIF o telèfon ja està registrat.');
        setNifError('Aquest DNI/NIF o telèfon ja està registrat.');
      } else {
        navigate('/auth/sign-in');
      }
    } finally {
      setLoading(false);
    }
  }

  function validatePhone() {
    if (phone.length === 0) {
      setPhoneError('El telèfon no pot estar buit.');
    } else {
      setPhoneError(null);
      return true;
    }

    return false;
  }

  function validatePassword() {
    if (password.length === 0) {
      setPasswordError('La contrasenya no pot estar buida.');
    } else {
      setPasswordError(null);
      validatePasswordRepeat(true);
      return true;
    }

    return false;
  }

  function validatePasswordRepeat(fromPasswordValidator: boolean) {
    if (fromPasswordValidator) {
      if (passwordRepeatError === 'Les contrasenyes no coincideixen.') {
        if (passwordRepeat === password) {
          setPasswordRepeatError(null);
          return true;
        }
      }

      return false;
    }

    if (passwordRepeat.length === 0) {
      setPasswordRepeatError('La contrasenya no pot estar buida.');
    } else if (passwordRepeat !== password) {
      setPasswordRepeatError('Les contrasenyes no coincideixen.');
    } else {
      setPasswordRepeatError(null);
      return true;
    }

    return false;
  }

  function validateName() {
    if (name.length === 0) {
      setNameError('El nom no pot estar buit.');
    } else {
      setNameError(null);
      return true;
    }

    return false;
  }

  function validateSurname1() {
    if (surname1.length === 0) {
      setSurname1Error('El primer cognom no pot estar buit.');
    } else {
      setSurname1Error(null);
      return true;
    }

    return false;
  }

  function validateSurname2() {
    setSurname2Error(null);
    return true;
  }

  function validateNif() {
    if (!/[XYZ]?\d{8}[A-Z]/i.test(nif)) {
      setNifError('Introdueix un DNI/NIF vàlid.');
    } else {
      setNifError(null);
      return true;
    }

    return false;
  }

  function validateEmail() {
    if (email.length === 0) {
      setEmailError('L\'adreça electrònica no pot estar buida.');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setEmailError('L\'adreça electrònica no és vàlida.');
    } else {
      setEmailError(null);
      return true;
    }

    return false;
  }

  function validateAddress() {
    if (address.length === 0) {
      setAddressError('L\'adreça no pot estar buida.');
    } else {
      setAddressError(null);
      return true;
    }

    return false;
  }

  function validateAcceptTos() {
    if (!acceptToS) {
      setAcceptToSError('Acceptar els terminis és obligatori.');
    } else {
      setAcceptToSError(null);
      return true;
    }

    return false;
  }

  return (
    <Layout title="Registra't">
      <div className="auth-item">
        <span className="auth-item-label">Telèfon</span>
        <input
          value={phone}
          onChange={event => setPhone(event.target.value)}
          onBlur={validatePhone}
        />
        {
          phoneError !== null
            ? (
              <span className="auth-item-error">{phoneError}</span>
            )
            : null
        }
      </div>

      <div className="auth-item">
        <span className="auth-item-label">Contrasenya</span>
        <input
          value={password}
          onChange={event => setPassword(event.target.value)}
          onBlur={validatePassword}
          type="password"
        />
        {
          passwordError !== null
            ? (
              <span className="auth-item-error">{passwordError}</span>
            )
            : null
        }
      </div>

      <div className="auth-item">
        <span className="auth-item-label">Repeteix la contrasenya</span>
        <input
          value={passwordRepeat}
          onChange={event => setPasswordRepeat(event.target.value)}
          onBlur={() => validatePasswordRepeat(false)}
          type="password"
        />
        {
          passwordRepeatError !== null
            ? (
              <span className="auth-item-error">{passwordRepeatError}</span>
            )
            : null
        }
      </div>

      <div className="auth-item">
        <span className="auth-item-label">Nom</span>
        <input
          value={name}
          onChange={event => setName(event.target.value)}
          onBlur={validateName}
        />
        {
          nameError !== null
            ? (
              <span className="auth-item-error">{nameError}</span>
            )
            : null
        }
      </div>

      <div className="auth-item">
        <span className="auth-item-label">Primer cognom</span>
        <input
          value={surname1}
          onChange={event => setSurname1(event.target.value)}
          onBlur={validateSurname1}
        />
        {
          surname1Error !== null
            ? (
              <span className="auth-item-error">{surname1Error}</span>
            )
            : null
        }
      </div>

      <div className="auth-item">
        <span className="auth-item-label">Segon cognom</span>
        <input
          value={surname2}
          onChange={event => setSurname2(event.target.value)}
          onBlur={validateSurname2}
        />
        {
          surname2Error !== null
            ? (
              <span className="auth-item-error">{surname2Error}</span>
            )
            : null
        }
      </div>

      <div className="auth-item">
        <span className="auth-item-label">DNI/NIF</span>
        <input
          value={nif}
          onChange={event => setNif(event.target.value)}
          onBlur={validateNif}
        />
        {
          nifError !== null
            ? (
              <span className="auth-item-error">{nifError}</span>
            )
            : null
        }
      </div>

      <div className="auth-item">
        <span className="auth-item-label">Adreça electrònica</span>
        <input
          value={email}
          onChange={event => setEmail(event.target.value)}
          onBlur={validateEmail}
        />
        {
          emailError !== null
            ? (
              <span className="auth-item-error">{emailError}</span>
            )
            : null
        }
      </div>

      <div className="auth-item">
        <span className="auth-item-label">Adreça</span>
        <textarea
          value={address}
          onChange={event => setAddress(event.target.value)}
          onBlur={validateAddress}
        />
        {
          addressError !== null
            ? (
              <span className="auth-item-error">{addressError}</span>
            )
            : null
        }
      </div>

      <div className="auth-item">
        <div>
          <input
            type="checkbox"
            checked={acceptToS}
            onChange={event => setAcceptToS(event.target.checked)}
            onBlur={validateAcceptTos}
          />
          <span className="auth-item-label" style={{ paddingLeft: 8 }}>Terminis del Servei</span>
        </div>
        {
          acceptToSError !== null
            ? (
              <span className="auth-item-error">{acceptToSError}</span>
            )
            : null
        }
      </div>

      <button className="auth-button" onClick={onSubmit}>Registra't</button>
      <button className="auth-button" onClick={() => navigate('/auth/sign-in')}>
        Ja m'he registrat
      </button>

      {
        isLoading
          ? (
            <LoadingSpinner />
          )
          : null
      }
    </Layout>
  );
}
