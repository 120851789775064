import React from 'react';
import { useNavigate } from 'react-router';

import logoImg from '../assets/logo.png';

import './Header.css';


export default function Header(props: {
  withBackButton?: boolean;
  backButtonType?: 'go-back' | 'open-menu';
  backButtonTo?: string | (() => void);
}) {
  const navigate = useNavigate();

  function onClick() {
    if (props.backButtonTo !== undefined) {
      if (typeof props.backButtonTo === 'string')
        navigate(props.backButtonTo);
      else
        props.backButtonTo();
    } else
      navigate(-1);
  }

  return (
    <div className="header-container">
      {
        props.withBackButton === true
          ? (
            <div className="header-back-button" onClick={onClick}>
              {
                (props.backButtonType ?? 'go-back') === 'go-back'
                  ? (
                    <i className="bi-arrow-left-short" />
                  )
                  : (
                    <i className="bi-list" />
                  )
              }
            </div>
          )
          : null
      }
      <div className="header-spacer" />
      <img className="header-logo" src={logoImg} alt="VilaMercat logo" />
    </div>
  );
}
