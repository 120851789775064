import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useAlert } from '../components/Alert';
import Layout from '../components/Layout';
import LoadingSpinner from '../components/LoadingSpinner';
import ordersService, { Shop } from '../services/OrdersService';

import './OrderView.css';


export default function OrderView() {
  const alert = useAlert();
  const params = useParams<'orderId'>();

  const [isLoading, setLoading] = useState(true);
  const [date, setDate] = useState<Date | null>(null);
  const [shops, setShops] = useState<Array<Shop>>([]);

  async function refreshOrderDetails() {
    setLoading(true);

    try {
      const orderDetails = await ordersService.fetchOrderDetails(params.orderId!);

      setDate(orderDetails!.date);
      setShops(orderDetails!.shops);
    } catch (e) {
      alert.show({
        type: 'retry',
        title: 'Error',
        message: 'No s\'ha pogut obtenir la informació de la comanda.',
        onDismiss: () => {
          alert.hide();
          refreshOrderDetails();
        },
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    refreshOrderDetails();
  }, []);

  return (
    <Layout
      title={`Comanda del ${date ? dayjs(date).format('DD/MM/YYYY') : '?'}`}
      withBackButton
    >
      <div className="orderview-shops">
        {
          shops.map((it, idx) => (
            <div key={idx} className="orderview-shop">
              {it.name}
              {
                idx + 1 < shops.length
                  ? <hr />
                  : null
              }
            </div>
          ))
        }
      </div>

      {alert.render()}
      {
        isLoading
          ? (
            <LoadingSpinner />
          )
          : null
      }
    </Layout>
  );
}
