import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { useAlert } from '../components/Alert';
import Layout from '../components/Layout';
import LoadingSpinner from '../components/LoadingSpinner';
import ordersService from '../services/OrdersService';
import slotsService, { useTimeSlots } from '../services/SlotsService';

import './SelectTimeSlot.css';


function getWeekDayName(weekDay: number) {
  switch (weekDay) {
    case 1:
      return 'Dilluns';
    case 2:
      return 'Dimarts';
    case 3:
      return 'Dimecres';
    case 4:
      return 'Dijous';
    case 5:
      return 'Divendres';
    case 6:
      return 'Dissabte';
    case 0:
      return 'Diumenge';
  }
}

function getDaytimeSection(dayTime: Date) {
  if (dayTime.getHours() <= 14) {
    return 'Matí';
  } else {
    return 'Tarda';
  }
}


export default function SelectTimeSlot(props: {
  onSelect: (timeSlotStart: Date) => Promise<void>;
  onGoBack: () => void;
}) {
  const alert = useAlert();
  const timeSlots = useTimeSlots();

  const [isLoading, setLoading] = useState(true);
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
  const [orderPricing, setOrderPricing] = useState<number | null>(null);

  async function onClick() {
    if ((timeSlots?.length ?? 0) === 0) {
      props.onGoBack();
    }

    if (selectedIdx === null) {
      return;
    }

    setLoading(true);
    await props.onSelect(timeSlots![selectedIdx].startTime);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    Promise.all([
      slotsService.refreshTimeSlots()
        .catch(e => alert.show({
          type: 'message',
          title: 'Error',
          message: 'No s\'han pogut obtenir les franjes d\'entrega disponibles. '
            + 'Torna-ho a provar més tard.',
          onDismiss: () => {
            alert.hide();
            props.onGoBack();
          },
        })),
      ordersService.fetchPricing()
        .then(value => setOrderPricing(value)),
    ]).finally(() => setLoading(false));
  }, []);

  const elements: Array<React.ReactNode> = [];
  let previousDate: Date | null = null as any;

  let counter = 0;
  for (const { startTime, endTime } of timeSlots!) {
    if (previousDate === null || !(
      previousDate.getFullYear() === startTime.getFullYear() &&
      previousDate.getMonth() === startTime.getMonth() &&
      previousDate.getDate() === startTime.getDate()
    )) {
      elements.push(
        <div
          key={elements.length}
          className={elements.length === 0 ? 'selecttimeslot-weekday' : 'selecttimeslot-weekday selecttimeslot-padded'}
        >{getWeekDayName(startTime.getDay())}</div>,
        <div key={elements.length + 1}>
          {startTime.getDate()}
          /
          {startTime.getMonth() + 1}
          /
          {startTime.getFullYear()}
        </div>,
        <div key={elements.length + 2} className="selecttimeslot-section">
          {getDaytimeSection(startTime)}
          <hr className="selecttimeslot-hr" />
        </div>
      );
    } else if (getDaytimeSection(previousDate) !== getDaytimeSection(startTime)) {
      elements.push(
        <div key={elements.length} className="selecttimeslot-section">
          {getDaytimeSection(startTime)}
          <hr className="selecttimeslot-hr" />
        </div>
      );
    }

    const idx = counter;
    elements.push(
      <div
        key={elements.length}
        className={selectedIdx === counter ? 'selecttimeslot-item selecttimeslot-selected' : 'selecttimeslot-item'}
        onClick={() => setSelectedIdx(idx)}
      >
        {dayjs(startTime).format('HH:mm')} - {dayjs(endTime).format('HH:mm')}
        <hr className="selecttimeslot-hr" />
      </div>
    );

    previousDate = startTime;
    counter++;
  }

  return (
    <Layout title="Tria hora d'entrega" withBackButton>
      <div className="selecttimeslot-pricing">
        Preu de la entrega:
        <span className="selecttimeslot-pricing-price">{orderPricing?.toFixed(2)} €</span>
      </div>
      <br />

      {elements}
      {
        timeSlots === null || timeSlots.length === 0
          ? (
            <>No hi ha hores disponibles.</>
          )
          : null
      }

      <button className="selecttimeslot-button" onClick={onClick}>
        {
          (timeSlots?.length ?? 0) === 0
            ? <>Torna enrere</>
            : <>Següent</>
        }
      </button>

      {alert.render()}
      {
        isLoading || timeSlots === null
          ? (
            <LoadingSpinner />
          )
          : null
      }
    </Layout>
  );
}
