import { useEffect, useState } from 'react';
import { Observer, Subject, Subscription } from 'rxjs';

import config from '../config';


export type UserInfo = {
  phone: string;
  name: string;
  surname1: string;
  surname2?: string;
  email: string;
  address: string;
  
  userType: 'normal' | 'delivery' | 'admin';
};

export type Event = UserInfo | null;


class AuthService {
  private eventDispatcher: Subject<Event>;

  private _currentUser: undefined | null | UserInfo;

  public constructor() {
    this.eventDispatcher = new Subject();
    this._currentUser = undefined;

    this.refreshCurrentUser();
  }

  public get currentUser() {
    return this._currentUser;
  }

  public async updateInfo(newInfo: Partial<UserInfo & { password?: string; }>) {
    const res = await fetch(new URL('auth/self', config.baseUrl).toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(newInfo),
      credentials: 'same-origin',
    });
    if (res.status === 200) {
      this._currentUser = (await res.json())['userInfo'] as UserInfo;
    } else {
      this._currentUser = null;
    }
    this.eventDispatcher.next(this._currentUser);
  }

  public async signIn(params: {
    phone: string;
    password: string;
  }) {
    const res = await fetch(new URL('auth/sign-in', config.baseUrl).toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(params),
      credentials: 'same-origin',
    });
    if (res.status === 200) {
      this._currentUser = (await res.json())['userInfo'] as UserInfo;
      this.eventDispatcher.next(this._currentUser);

      return true;
    } else if (res.status === 401) {
      return false;
    } else {
      throw new Error((await res.json())['error']);
    }
  }

  public async signUp(params: {
    nif: string;
    password: string;
    name: string;
    surname1: string;
    surname2?: string;
    email: string;
    phone: string;
    address: string;
  }) {
    const res = await fetch(new URL('auth/sign-up', config.baseUrl).toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(params),
      credentials: 'same-origin',
    });
    if (res.status === 200) {
      return true;
    } else if (res.status === 403) {
      return false;
    } else {
      throw new Error((await res.json())['error']);
    }
  }

  public async signOut() {
    if (this.currentUser === null || this.currentUser === undefined)
      return;

    const res = await fetch(new URL('auth/sign-out', config.baseUrl).toString(), {
      method: 'POST',
      credentials: 'same-origin',
    });
    if (res.status === 200) {
      this._currentUser = null;
      this.eventDispatcher.next(null);

      return true;
    }

    return false;
  }

  public subscribe(observer: Partial<Observer<Event>>): Subscription {
    return this.eventDispatcher.subscribe(observer);
  }

  public async refreshCurrentUser() {
    const res = await fetch(new URL('auth/self', config.baseUrl).toString(), {
      credentials: 'same-origin',
    });
    if (res.status === 200) {
      this._currentUser = (await res.json())['userInfo'] as UserInfo;
    } else {
      this._currentUser = null;
    }
    this.eventDispatcher.next(this._currentUser);
  }
}

const authService = new AuthService();
export default authService;


export function useCurrentUser() {
  const [currentUser, setCurrentUser] = useState<undefined | null | UserInfo>(
    authService.currentUser
  );

  useEffect(() => {
    const subscription = authService.subscribe({
      next: event => setCurrentUser(event),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return currentUser;
}
