import React from 'react';

import LoadingSpinner from '../components/LoadingSpinner';
import OrdersList from '../components/OrdersList';
import { useDeliveredOrders } from '../services/OrdersService';


export default function DeliveredOrders() {
  const deliveredOrders = useDeliveredOrders();

  return (
    <>
      <OrdersList
        title="Entregades"
        navBarTab="delivered"
        orders={deliveredOrders ?? []}
      />

      {
        deliveredOrders === undefined
          ? (
            <LoadingSpinner />
          )
          : null
      }
    </>
  );
}
