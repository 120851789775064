import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAlert } from '../../components/Alert';
import Layout from '../../components/Layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import slotsService, { TimeSlotDefinition } from '../../services/SlotsService';

import './PresetEdit.css';


export default function PresetEdit() {
  const alert = useAlert();
  const params = useParams<'name'>();
  const navigate = useNavigate();

  const [timeSlots, setTimeSlots] = useState<Array<TimeSlotDefinition>>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    new Promise<void>(async (resolve, reject) => {
      try {
        setTimeSlots(await slotsService.fetchPresetInfo(params['name']!));
      } catch (e) {
        reject(e);
        return;
      }

      resolve();
    })
      .then(() => setLoading(false))
      .catch(() => {
        // TODO: Handle error.
      });
  }, []);

  function onClickDelete(startTime: Date) {
    alert.show({
      title: 'Confirmació',
      type: 'confirm',
      message: 'Segur que vols esborrar la franja del grup?',
      onDismiss: async role => {
        alert.hide();
        if (role === 'ok') {
          setLoading(true);
          try {
            setTimeSlots(await slotsService.modifyPreset(params.name!, {
              del: [startTime],
            }));
          } finally {
            setLoading(false);
          }
        }
      }
    });
  }

  return (
    <Layout title="Edita el grup" withBackButton>
      <button
        className="presetedit-button"
        onClick={() => navigate(`/admin/slots/preset/${params.name!}/apply`)}
      >Aplicar</button>

      <div className="presetedit-section">
        <div className="presetedit-section-header">
          <div className="presetedit-section-header-row">
            Franges horàries
            <i className="bi-plus-lg" onClick={() => navigate(`/admin/slots/preset/${params.name}/new-slot`)} />
          </div>
          <hr className="presetedit-hr" />
        </div>

        {
          timeSlots.length === 0
            ? (
              "No hi ha cap franja predefinida."
            )
            : (
              timeSlots.map((it, idx) => (
                <div key={idx} className="presetedit-item">
                  {dayjs(it.startTime).format('HH:mm')} - {dayjs(it.endTime).format('HH:mm')} ({it.maxOrders})
                  <i className="bi-trash" onClick={() => onClickDelete(it.startTime)} />
                </div>
              ))
            )
        }
      </div>

      {alert.render()}
      {
        isLoading
          ? (
            <LoadingSpinner />
          )
          : null
      }
    </Layout>
  )
}
